@import "../../styles/variables.scss";

.dates-container {
  display: flex;
  flex-direction: "row";
  align-items: center;
  & > div:first-of-type {
    margin-right: $spacing-xxxl;
  }
  .header-label {
    margin-right: $spacing-xl;
    font-size: 16px;
    font-weight: bold;
  }
  .date-label {
    margin-right: $spacing-base;
  }
}
