@import "../../../styles/variables.scss";

.card-container {
  padding: $spacing-xs;
  height: 100%;
}

.card-wrapper.card-wrapper {
  padding: $spacing-xs;
}
