@import "../../../../../../styles/variables.scss";
.data-options-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  .data-card-container {
    min-width: 700px;

    margin: 16px;
  }
  .contract-power-container {
    height: 100px;
    margin-top: 16px;
    margin-right: 16px;
    .card-container {
      justify-content: center;
    }
  }
  .data-option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 70px;
    & > *:nth-child(2) {
      margin-left: $spacing-xxxl;
    }
    .option-item {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row;
      .label {
        font-size: 16px;
        font-weight: bold;
        padding-right: $spacing-xs;
      }
    }
  }
  & > div:first-child {
    flex: 1;
  }
}
@media (max-width: 1600px) {
  .data-options-container {
    flex-direction: column;
  }
}
