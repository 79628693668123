@import '../../public/assets/sass/layout/mixins';
@import '../../public/assets/sass/variables/layout//common';
@import "./variables.scss";

@media screen and (max-width: $tabletBreakpoint) {
    .layout-wrapper .layout-topbar .topbar-left {
        @include flex-justify-start();

        .layout-breadcrumb {
            margin-left: $spacing-s;
        }
    }

}

.p-breadcrumb{
    border-width: 0;
}
.p-tree.p-component.p-tree-selectable {
    padding-right: $spacing-s;

    .p-treenode-content.p-treenode-selectable {
        padding-right: $spacing-m;
    }
}

