//spacing
$spacing-base: 8px;
$spacing-xs: 16px;
$spacing-s: 24px;
$spacing-m: 32px;
$spacing-l: 40px;
$spacing-xl: 48px;
$spacing-xxl: 56px;
$spacing-xxxl: 64px;
$spacing-xxxxl: 72px;
$spacing-xxxxxl: 80px;

//font-size
$font-xxl: 60px;
$font-s: 14px;
$font-m: 16px;

//colors
$black: black;
$white: white;
$red: #e82413;
$grey: #888888;
$green: #2da71c;
$yellow: #ffc300;
$blue: #7cb5ec;
$dark-blue: #2d8ceb;
$light-red: #f15c80;
$light-grey: #dddddd;
