@import "../../styles/variables.scss";

.buttons-section-container {
  display: flex;
  justify-content: flex-end;
  padding-left: $spacing-base;
}

.section-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.section-wrapper h3 {
  margin-bottom: 0;
  margin-left: $spacing-base;
}

.radio-button-section__radiobutton {
  margin-left: 10px;
}

.radio-button-section__radiobutton-label {
  margin-left: 5px;
}

.title-section {
  padding: 10px;
}

.section-wrapper-column {
  flex-direction: column;
}
