@import "../../styles/variables.scss";

.date-filter-section-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .resolution-text {
    margin: 0 $spacing-base 0 $spacing-base;
  }
  .p-dropdown {
    width: 120px;
  }
}

@media (max-width: 1750px) {
  .date-filter-section-container {
    flex-direction: column;
    .datefilter-wrapper {
      margin-bottom: 15px;
    }
  }
}
