.contracted-power-section-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.current-contracted-power-section-card-container {
  min-width: 250px;
  margin: 16px;
}
