@import "../../styles/variables.scss";

.time-select {
  width: 100px;
}

.date-select {
  width: 150px;
  margin-inline: $spacing-base;
}

.date-select-wrapper {
  display: flex;
  align-items: center;
}

.date-select-element {
  display: flex;
  align-items: center;
  padding-right: $spacing-base;
}

.comparison-filter-item {
  margin: 0px $spacing-base;
}
.resolution-text {
  margin: 0 $spacing-base 0 $spacing-m;
}

.p-dropdown {
  min-width: 120px;
}

.time-filter-section-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 620px) {
  .resolution-text {
    margin: 0 $spacing-base 0 $spacing-base;
  }
}
