@import "../../styles/variables.scss";
.p-grid > .overview-box-container {
  padding: 16px;
}
.widget-overview-box {
  display: flex;
  flex-direction: column;
  min-height: 170px;
  height: 100%;
  justify-content: space-between;
  .title-container {
    display: flex;
    flex-direction: row;
    .texts-container {
      .overview-title {
        display: inline-block;
        font-size: 1.2rem;
      }
    }
  }
  .overview-detail {
    margin-top: 0;
    .overview-container {
      padding: 0;
      .overview-number {
        font-size: 2rem;
      }
      .overview-subtext {
        font-size: 1rem;
      }
    }
  }
}
