@import "../../../../../../styles/variables.scss";

.options-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .localizations-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > div:first-of-type {
      margin-right: $spacing-xxxl;
    }
    .header-label {
      margin-right: $spacing-xl;
      font-size: 16px;
      font-weight: bold;
    }
    .localization-label {
      margin-right: $spacing-base;
    }
  }
}
