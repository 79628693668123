.cursor-pointer {
  cursor: pointer;
}

.chart-notes-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.notes-list {
  width: 20%;
  margin-right: 5px;
  margin-bottom: 2px;
}
