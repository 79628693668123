@import "../../../styles/variables.scss";

.layout-content.map {
  display: flex;
  min-height: 91vh;
  padding: 0;
}

.grid {
  flex-grow: 1;
}
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.map-container {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
}
