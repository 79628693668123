@import "../../styles/variables.scss";

.dialog-container {
  display: flex;
  flex-direction: column;
}
.p-dialog-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.button-container {
  padding-top: $spacing-s;
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.text-area {
  margin: 10px;
  overflow: auto;
  resize: none;
  height: stretch;
}

.note-dialog-information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 5px 0;
  padding: 5px 0;
}
