@import "../../../../styles/variables.scss";

.checkboxLabel {
  padding: 10px;
  font-size: 16px;
}

.selectionContainer {
  display: flex;
  align-items: center;
  height: 100%;
}

.selectionContainer h3 {
  margin-bottom: 0;
  margin-inline: $spacing-base;
}
