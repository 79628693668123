@import "../../../../../../styles/variables.scss";

.power-change-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 500px;
  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding-right: $spacing-l;
    .power-change-label {
      font-size: 16px;
      text-align: center;
      font-weight: bold;
    }
    .contract-power {
      text-align: center;
      font-size: 18px;
    }
    & > button:first-child {
      margin-right: $spacing-xs;
    }
    & > *:nth-child(2) {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    & > button:last-child {
      margin-left: $spacing-xs;
    }
  }
  .contract-power-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .power-change-differance {
      font-size: 28px;
      color: $grey;
    }
    .power-change-differance-wrapper {
      display: flex;
      margin-right: $spacing-xs;
    }

    .power-change-price {
      font-size: 16px;
      font-weight: bold;
      padding-top: 5px;
      & > span {
        font-weight: normal;
        font-size: 18px;
      }
    }

    .higher-cost {
      color: $light-red;
    }

    .lower-cost {
      color: $green;
    }
  }
}
