@import "../../../../../styles/variables.scss";

.map-info-window-container {
  position: relative;
  bottom: 185px;
  left: -100px;
  width: max-content;
  z-index: 1;
}
.map-info-window-container > * {
  padding: 0 !important;
}
.map-info-window-down {
  bottom: -28px;
}
.map-info-window-right {
  left: 0px;
}
.map-info-window-left {
  left: -200px;
}

.map-info-window-text {
  font-size: $font-s;
  margin-top: 8px;
  padding: 0 1rem;
  &:first-of-type {
    margin-top: 0px;
  }
}
