@import "../../../../../styles/variables.scss";

$icon-size: 34px;
$icon-size-house: 32px;

.map-marker-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(32px + 14px);
  width: calc(32px + 14px);
  border: 1px solid $black;
  border-radius: 25%;
  background-color: $dark-blue;
  box-shadow: 2px 2px 4px 0 rgba($color: $black, $alpha: 0.5);
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.map-marker-icon {
  background-image: url("../../../../../assets/images/solar-energy.svg");
  height: $icon-size-house;
  width: $icon-size-house;
  background-size: cover;
}

.street-lamp {
  height: $icon-size;
  width: $icon-size;
  background-image: url("../../../../../assets/images/street-light.svg");
}
.active {
  background-color: $blue;
}
