@import "../styles/variables.scss";

.login-wrapper {
  position: relative;
}

.login-images-container {
  position: relative;
}

.login-background-img {
  opacity: 0.18;
  background-color: white;
}

.image {
  z-index: -1;
  height: auto;
  width: 65%;
}

.login-logo {
  position: absolute;
  margin: auto;
  height: auto;
  width: 15%;

  left: 0;
  bottom: 5%;
  right: 0;
}

@media screen and (max-width: 1200px) {
  .image {
    width: 100%;
  }

  .login-logo {
    width: 25%;
  }
}

.login-container {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 60%;
}

.login-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &-image {
    margin-top: 3rem;
    height: 44.3%;
    width: 43.5%;
    background: url("../assets/images/bg-logo.svg");
    background-size: cover;
    background-position: center bottom;

    &-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
}

.login-title {
  color: $black;
  font-size: $font-xxl;
  padding: $spacing-base;
}

.wrong-password-message-container {
  padding-top: 10px;
  color: $red;
  font-size: $font-m;
}
