@import "../../styles/variables.scss";

.datefilter-wrapper {
  display: flex;
  align-items: center;
}

.datefilter-container {
  display: flex;
  align-items: center;
}

.datefilter-text {
  margin: 0px $spacing-base;
}

@media (max-width: 1250px) {
  .datefilter-wrapper {
    flex-direction: column;
  }
  .datefilter-container {
    margin-bottom: 15px;
  }
}
