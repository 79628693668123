@import "../styles/variables.scss";

.jdfhskhsdjkhkjsad {
  margin: auto;
}
.start-container {
  padding: $spacing-s $spacing-m;

  .start-title {
    margin-bottom: $spacing-xl;
  }
  .contact-section {
    margin-top: $spacing-xl;
    float: right;
  }
}
