@import "./styles/variables.scss";

.appmenu-logout {
  padding: $spacing-xs;
  font-weight: bold;
  color: #ccdff3;
  text-align: center;
  border-top: 1px solid #ccdff3;
  cursor: pointer;
}

.button-text span {
  color: #ccdff3;
}
