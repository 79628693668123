@import "./styles/variables.scss";

.topbar-title {
  width: 400px;

  @media screen and (min-width: 1200px) {
    width: 420px;
  }
  @media screen and (min-width: 1400px) {
    width: 440px;
  }
}

.topbar-main {
  display: none;

  @media screen and (min-width: 1100px) {
    display: flex;
    justify-content: space-between;
  }
}

.topbar-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 $spacing-base;
  padding: 0 $spacing-base;
}

.topbar-clock {
  display: none;

  @media screen and (min-width: 1399px) {
    display: block;
  }
}

.topbar-text {
  text-align: center;
  padding: 0 $spacing-base;
}

.topbar-info-title {
  font-weight: bold;
}

.topbar-details {
  display: flex;
  flex-direction: row;
  padding: 4px 0;
}

.topbar-network {
  display: flex;
  flex-direction: row;
}

.layout-topbar {
  position: relative;

  .tree-select-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    .p-treeselect {
      width: 300px;
    }
  }
}
